<template>
  <div style="height: 100%">
    <div class="header" style="margin-bottom: 20px">
      <!-- <div>{{ this.type === "1" ? "企业" : "个人" }}账户信息</div> -->
      <div class="header_top">
        <img src="../../assets/user.png" alt />
        <span class="center">用户管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ this.type === "1" ? "企业" : "个人" }}账户信息</span>
      </div>
      <div>
        <!-- <el-button
          type="primary"
          @click="updateList"
          :loading="refreshLoading"
          class="top_btn"
          >刷 新</el-button
        >-->
        <!-- <el-button type="text" @click="enterpriseVisble = true"
          >查看账户交易记录
        </el-button>-->
      </div>
    </div>
    <el-card>
      <div v-if="list.length === 0" class="empty-box">企业未开户！</div>
      <el-row :gutter="20" v-for="(it, i) of newList" :key="i">
        <el-col :span="8" v-for="(item, index) of it" :key="index">
          <div class="grid-content bg-purple">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-size: 15px;float:left">
                  {{
                  item.pay_platform | platformVal
                  }}
                </span>

                <el-button
                  style="float: right; padding: 3px 0;"
                  type="text"
                  @click="enterpriseRecharge(item)"
                >充值</el-button>
                <el-button
                  type="text"
                  style="float: right; padding: 3px 0;margin-right:5px"
                  @click="updateList(item)"
                  :loading="item.refreshLoading"
                >刷新</el-button>
              </div>
              <div style="text-align: left;color:#666;font-size:14px;">
                <p>帐户编号：{{ item.account }}</p>
                <p>帐户余额：{{ item.balance || 0 | money }}元</p>
                <!-- <p>结算中心：{{ item.pay_platform }}</p> -->
                <p>开户公司：{{ item.bankname }}</p>
                <p>公司地址：{{ item.bankarea }}</p>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </el-card>

    <!-- <div class="header">
      <div>财务操作人员</div>
    <div>-->
    <!-- <el-button type="text" @click="addDialogVisible = true"
          >创建结算户</el-button
    >-->
    <!-- <el-button type="text" @click="viewOperator">
          查看操作人员交易记录
        </el-button>
      </div>
    </div>
    <el-table :data="tableData" border style="width: 100%; margin-top: 20px">
      <el-table-column prop="subUser.name" label="操作人员" width="180">
      </el-table-column>
      <el-table-column prop="payPlatform" label="结算中心">
        <template slot-scope="scope">
          {{ scope.row.payPlatform | platformVal }}
        </template>
      </el-table-column>
      <el-table-column prop="availableBalance" label="可操作余额">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button
            type="text"
            @click="addRecharge(scope.row)"
            :loading="refreshLoading"
          >
            充值
    </el-button>-->
    <!-- <el-popconfirm
            title="确定禁用此帐户吗？帐户禁用后将不再具有操作权限！"
            @confirm="deleteAccount(scope.row)"
          >
            <el-button slot="reference" type="text">
              禁用
            </el-button>
    </el-popconfirm>-->
    <!-- </template>
      </el-table-column>
    </el-table>-->

    <!-- 创建结算户 -->
    <AddAccount :addDialogVisible.sync="addDialogVisible" @addClose="getSubAccountList"></AddAccount>
    <!-- 查看账户交易记录 -->
    <EnterpriseRecords :enterpriseVisble.sync="enterpriseVisble"></EnterpriseRecords>
    <!-- 操作人员交易记录 -->
    <OperatorRecord :operatorVisble.sync="operatorVisble" :index="index"></OperatorRecord>
    <!-- 充值 -->
    <Recharge
      :rechargeVisible.sync="rechargeVisible"
      :code="rechargeCode"
      @rechargeClose="rechargeClose"
    ></Recharge>
    <!-- 企业充值提示 -->
    <EnterpriseRecharge
      :enterpriseRechargeVisible.sync="enterpriseRechargeVisible"
      :enterpriseRechargeData="enterpriseRechargeData"
    ></EnterpriseRecharge>
  </div>
</template>
<script>
import { api } from '../../api';
import * as _ from 'lodash';
export const MybankAccountList = api('/mybank')('mybank.account.list.json');
export const MybankAccountUpdate = api('/mybank')('mybank.account.update.json');
export const FinancialSubAccountList = api('/financialSubAccount')(
  'financialSubAccount.list.json'
);
export const FinancialSubAccountDelete = api('/financialSubAccount')(
  'financialSubAccount.del.json'
);


export default {
  components: {
    AddAccount: () => import('./AddAccount'),
    EnterpriseRecords: () => import('./enterpriseRecord'),
    OperatorRecord: () => import('./operatorRecord'),
    Recharge: () => import('./Recharge'),
    EnterpriseRecharge: () => import('./enterpriseRecharge')
  },
  data () {
    return {
      tableData: [],
      code: '',
      loading: false,
      list: [],
      newList: [],
      type: '',
      refreshLoading: false,
      dialogVisible: false,
      rechargeData: {},
      enterpriseVisble: false,
      operatorVisble: false,
      addDialogVisible: false,
      rechargeVisible: false,
      rechargeCode: '',
      index: 0,
      enterpriseRechargeVisible: false,
      enterpriseRechargeData: {}
    };
  },

  created () {
    this.type = this.$route.query.type;
    if (this.$route.query.type === '1') {
      this.code =
        window.localStorage.getItem('enterprise') ||
        window.localStorage.getItem('enterpriseCode');
    } else if (this.$route.query.type === '2') {
      this.code = window.localStorage.getItem('code');
    } else {
      this.code = this.$route.query.code;
    }
    this.getUserInfo();
    this.getSubAccountList();
  },
  methods: {
    async getSubAccountList () {
      const res = await FinancialSubAccountList({
        pageNumber: 1,
        pageSize: 1000,
        $ignoreRepeat: true
      });
      this.tableData = res.list;
    },
    async getUserInfo () {
      this.loading = true;
      const result = await MybankAccountList({
        subjectCode: this.code
      });
      this.list = result.map(it => {
        return {
          ...it,
          refreshLoading: false
        }
      });
      const newData = _.groupBy(this.list, 'bankname')
      let _list = []
      Object.keys(newData).forEach(it => {
        _list.push(newData[it])
      })
      this.newList = _list
      this.loading = false;
    },
    async updateList (item) {
      this.loading = true;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].account == item.account)
          this.list[i].refreshLoading = true;
      }
      // this.refreshLoading = true;
      const result = await MybankAccountUpdate({
        subjectCode: localStorage.getItem('enterpriseCode'),
        platform: item.pay_platform
      });

      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].pay_platform === result.platformCode) {
          console.log(this.list[i]);
          this.list[i].refreshLoading = false;
          this.list[i].balance = result.balance;
        }
      }
      console.log(this.list);
      // this.list.filter(it => list1.account == it.account) == list1;
      // console.log(this.list.filter(it => it.account === list1.account));

      this.loading = false;
      this.refreshLoading = false;
      this.$message.success('帐户信息更新成功');
    },
    goBack () {
      window.history.go(-1);
    },
    recharge (data) {
      this.dialogVisible = true;
      this.rechargeData = data;
    },
    async deleteAccount (row) {
      await FinancialSubAccountDelete({
        code: row.subUserAccount
      });
      this.$message.success(`帐户${row.subUser.name}禁用成功！`);
    },
    addRecharge (row) {
      this.rechargeCode = row.code;
      this.rechargeVisible = true;
    },
    rechargeClose () {
      this.getSubAccountList();
    },
    viewOperator () {
      this.operatorVisble = true;
      this.index++;
    },
    enterpriseRecharge (item) {
      this.enterpriseRechargeData = item;
      this.enterpriseRechargeVisible = true;
    }
  }
};
</script>

<style lang="scss" scoped>
h4 {
  margin-top: 12px;
  margin-bottom: 16px;
  font-size: 15px;
}
.el-col {
  color: #555;
  line-height: 32px;
  padding-bottom: 8px;
  padding-left: 24px;
}
.header {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  font-weight: 400;
  color: #606266;
  cursor: pointer;
}
.showImgDialog .imgBox {
  display: flex;
}
.img-resource {
  width: 300px;
  height: 250px;
  margin: 0 auto;
}

.text {
  font-size: 14px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.empty-box {
  color: #666666;
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
}
</style>
